import { Grid } from "@material-ui/core";
import { navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import RightArrow from "../../assets/Icons/go.svg";
import Clm2Img from "../../assets/Icons/mail.svg";
import Clm1Img from "../../assets/Icons/phone.svg";
import Button from "../../components/Button";
import Layout from "../../components/layouts/layout";
import DataService from "../../services/api/data_services";
import { BASE_SITE_URL } from "../../shared/constants";
import ArticleTag from "../../templates/ArticleTag";
import Box from "../../templates/Box";
import ImageAvatar from "../../templates/ImageAvatar";
import Miniview from "../../templates/MiniView";
import ShareCard from "../../templates/ShareCard";
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
const Article = ({ pageContext: { article } }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [articleContent, setArticleContent] = useState([]);
  const [articleTag, setArticleTag] = useState([]);
  const queryString =
    typeof window !== "undefined" ? window.location.href.split("/")[4] : "";
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };
  // custom hook for getting previous value
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevCount = usePrevious(articleData);
  useEffect(() => {
    setLimit(6);
    setPage(1);
    setArticleData([]);
    setArticleRawData([]);
    DataService.getData(`/admin/api/post-details?slug=${queryString}`).then(
      (response) => {
        fetchRelatedArtciles(response.data.tag_lists, response.data.id);
        const articleContent = response.data;
        setArticleContent(articleContent);
        setArticleTag(response.data.article_tag);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);
  useEffect(() => {
    DataService.getData(`/admin/api/post-details?slug=${queryString}`).then(
      (response) => {
        fetchRelatedLoadmoreArtciles(response.data.tag_lists, response.data.id);
        const articleContent = response.data;
        setArticleContent(articleContent);
        setArticleTag(response.data.article_tag);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);
  // console.log("articleContent :>> ", articleContent);
  const fetchRelatedLoadmoreArtciles = (queryVal, id) => {
    let value = queryVal?.map((val) => `article_type[]=${val}`).join("&");
    DataService.getData(
      `/admin/api/article-tag-multiple?limit=${limit}&page=${page}&current_id=${id}&type=Article&${value}`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
  };
  const fetchRelatedArtciles = (queryVal, id) => {
    let value = queryVal?.map((val) => `article_type[]=${val}`).join("&");
    DataService.getData(
      `/admin/api/article-tag-multiple?limit=${limit}&page=${1}&current_id=${id}&type=Article&${value}`
    )
      .then((response) => {
        if (prevCount === undefined || response.data) {
          setArticleData(response.data);
          setArticleRawData(response);
        } else {
          setArticleData([...articleData, ...response.data]);
          setArticleRawData(response);
        }
      })
      .catch((error) => {
        navigate(-1);
      });
  };

  /**
   * TwoColumnContactInfo
   */
  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };
  /**
   * Box component
   */
  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "CAREER",
    boxBtn1Title: "Join our team",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1SubTitle:
      "Here, you’re not “just” a developer or a designer! Take a step forward and join us!",
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "GET IN TOUCH",
    boxBtn2Title: "Let's talk",
    boxBtn2SubTitle: "We'd love to help with your product.",
    route1: "/contact-us",
    route2: "/contact-us",
  };
  /**
   * Image avatar component
   */
  const imageAvatarData = {
    image: articleContent.author_image,
    alt: articleContent.author_name,
    name: articleContent.author_name,
    date: articleContent.published_date,
    title: articleContent.position,
  };
  return (
    <Layout
      seo={{
        title: articleContent?.og_title,
        ogtitle: article?.og_title,
        description: article
          ? article.og_description
          : articleContent?.og_description,
        ogurl: `${BASE_SITE_URL}/article/${article?.slug}/`,
        ogimage: article?.og_image,
      }}
    >
      {articleContent && articleContent.body_content && (
        <div className="mt-70">.</div>
      )}
      <div className="mt-70 body-container">
        <Grid container direction="row" spacing={3}>
          {/* {articleContent && articleContent.body_content && ( */}
          <Grid style={{ position: "relative" }} item xs={12} md={2}>
            <ImageAvatar imageAvatarData={imageAvatarData} />
            <span style={{ position: "absolute", top: "70%" }}>
              {articleData && articleData.length > 0 && (
                <ShareCard
                  url={`${BASE_SITE_URL}/article/${
                    articleContent && articleContent.slug
                  }`}
                  type={articleContent.slug_type}
                  slug={articleContent.slug}
                />
              )}
            </span>
          </Grid>
          {/* )} */}
          <Grid item xs={12} md={8}>
            {articleContent && articleContent.body_content && (
              <div
                className="title-header"
                dangerouslySetInnerHTML={{
                  __html: `${articleContent.title}`,
                }}
              />
            )}
            <div className="mt-50"></div>
            {articleContent && articleContent.body_content && (
              <div
                className="article_content"
                dangerouslySetInnerHTML={{
                  __html: `${articleContent.body_content}`,
                }}
              />
            )}
            <div className="mt-30">
              <ArticleTag articleTag={articleTag} articleTagApi={articleTag} />
            </div>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
      </div>
      {articleData && articleData.length > 0 && (
        <React.Fragment>
          <div className="body-container mt-70">
            <div className="title-header">
              {" "}
              <span className="red-line3letter">Rel</span>ated Articles
            </div>
          </div>
          <div className="mt-50">
            <Miniview miniviewData={articleData} />
          </div>
        </React.Fragment>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page + 1);
              setLimit(6);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      {articleData && articleData.length > 0 && <Box BoxData={BoxData} />}
      {articleData && articleData.length > 0 && (
        <div>
          <TwoColumnContactInfo
            TwoColumnContactInfoData={TwoColumnContactInfoData}
          />
        </div>
      )}
    </Layout>
  );
};
export default Article;
